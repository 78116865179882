var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"self-links-filter-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.pageHelpMessageOrDescription(_vm.module.model)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{attrs:{"title":_vm.backButtonTitle},on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('filter-setting-layout',{attrs:{"filter-model":_vm.module.model},scopedSlots:_vm._u([{key:"tab-main",fn:function(){return [_c('a-card',{attrs:{"title":_vm.$t('field_special_settings_title')}},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'self_links_accept_mention_users',
              'hasAnchor': true,
              'disabled': _vm.disabled
            }
          }}}),_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'self_links_strict_mode',
              'hasAnchor': true,
              'disabled': _vm.disabled
            }
          }}}),_c('switch-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'search_links_in_qr_codes',
              'hasAnchor': true,
              'disabled': _vm.disabled
            }
          }}}),_c('switch-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'search_links_in_quotes',
              'hasAnchor': true,
              'disabled': _vm.disabled
            }
          }}}),_c('handle-user-bio-field',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.filter.handle_user_bio),callback:function ($$v) {_vm.$set(_vm.module.model.filter, "handle_user_bio", $$v)},expression:"module.model.filter.handle_user_bio"}}),_c('switch-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module,
              'key': 'self_links_dont_use_join',
              'hasAnchor': true,
              'disabled': _vm.disabled
            }
          }}}),(!_vm.module.model.params.tg_links_whitelist.items.length)?_c('a-alert',{attrs:{"message":_vm.$t('tg_links_whitelist_disabled_alert_message'),"show-icon":"","type":"warning"}}):_vm._e(),_c('black-white-list-input',{staticClass:"mb-0",class:{
            'mt-0': !_vm.module.model.params.tg_links_whitelist.items.length
          },attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'tg_links_whitelist',
              'hasAnchor': true,
              'settingWrapper': {
                'hasFieldButton': true,
                'disabledValue': {
                  type: 'Blacklist',
                  items: [ ]
                },
                'customiseValue': {
                  type: 'Blacklist',
                  items: [ ]
                },
              },
              'disabled': _vm.disabled
            }
          }}})],1),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.module.model.params.tg_links_whitelist.type === _vm.BlackWhiteListItemType.Whitelist
            || _vm.module.model.params.tg_links_whitelist.type === _vm.BlackWhiteListItemType.Blacklist && _vm.module.model.params.tg_links_whitelist.items.length)?_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_self_links_section_exceptions')}},[(_vm.module.model.params.tg_links_whitelist.type === _vm.BlackWhiteListItemType.Whitelist)?[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params,
                  'key': 'self_links_whitelist_allow_users',
                  'hasAnchor': true,
                  'tariffTags': _vm.getTagsByFieldKey('self_links_accept_mention_users'),
                  'disabled': _vm.disabled
                }
              }}}),_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params,
                  'key': 'self_links_whitelist_allow_bots',
                  'hasAnchor': true,
                  'tariffTags': _vm.getTagsByFieldKey('self_links_accept_mention_users'),
                  'disabled': _vm.disabled
                }
              }}}),_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params,
                  'key': 'self_links_whitelist_allow_chats',
                  'hasAnchor': true,
                  'tariffTags': _vm.getTagsByFieldKey('self_links_accept_mention_users'),
                  'disabled': _vm.disabled
                }
              }}}),_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params,
                  'key': 'self_links_whitelist_allow_channels',
                  'hasAnchor': true,
                  'tariffTags': _vm.getTagsByFieldKey('self_links_accept_mention_users'),
                  'disabled': _vm.disabled
                }
              }}})]:_vm._e(),(_vm.module.model.params.tg_links_whitelist.type === _vm.BlackWhiteListItemType.Blacklist && _vm.module.model.params.tg_links_whitelist.items.length)?[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params,
                  'key': 'self_links_blacklist_forbid_users',
                  'hasAnchor': true,
                  'tariffTags': _vm.getTagsByFieldKey('self_links_accept_mention_users'),
                  'disabled': _vm.disabled
                }
              }}}),_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params,
                  'key': 'self_links_blacklist_forbid_bots',
                  'hasAnchor': true,
                  'tariffTags': _vm.getTagsByFieldKey('self_links_accept_mention_users'),
                  'disabled': _vm.disabled
                }
              }}}),_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params,
                  'key': 'self_links_blacklist_forbid_chats',
                  'hasAnchor': true,
                  'tariffTags': _vm.getTagsByFieldKey('self_links_accept_mention_users'),
                  'disabled': _vm.disabled
                }
              }}}),_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params,
                  'key': 'self_links_blacklist_forbid_channels',
                  'hasAnchor': true,
                  'tariffTags': _vm.getTagsByFieldKey('self_links_accept_mention_users'),
                  'disabled': _vm.disabled
                }
              }}})]:_vm._e()],2):_vm._e()],1),_c('filter-journal',{attrs:{"warn-field":_vm.module.model.journal_moderation_warn,"ban-field":_vm.module.model.journal_moderation_ban,"disabled":_vm.disabled},on:{"update:warnField":function($event){return _vm.$set(_vm.module.model, "journal_moderation_warn", $event)},"update:warn-field":function($event){return _vm.$set(_vm.module.model, "journal_moderation_warn", $event)},"update:banField":function($event){return _vm.$set(_vm.module.model, "journal_moderation_ban", $event)},"update:ban-field":function($event){return _vm.$set(_vm.module.model, "journal_moderation_ban", $event)}}}),_c('filter-extra-settings',{attrs:{"ignore-caption":"","disabled":_vm.disabled},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true},{key:"tab-regular",fn:function(){return [_c('filter-common-settings',{attrs:{"fields-prefix":_vm.module.model.snakeCaseFilterType,"disabled":_vm.disabled},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true},{key:"tab-new",fn:function(){return [_c('filter-new-users-settings',{attrs:{"fields-prefix":_vm.module.model.snakeCaseFilterType,"disabled":_vm.disabled},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true},{key:"tab-edit",fn:function(){return [_c('filter-remove-edited-setting',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }